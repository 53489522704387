import React, { useState } from 'react';
import DesktopSidebar from './DesktopSidebar';
import MobileSidebar from './MobileSidebar';
import '../index.css';

function Sidebar() {

  return (
    <div >
      {/* Desktop Sidebar */}
      <div className="d-none d-md-block">
        <DesktopSidebar />
      </div>

      {/* Mobile Sidebar */}
      <div className="d-md-none">
        <MobileSidebar />
      </div>
    </div>
  );
}

export default Sidebar;
