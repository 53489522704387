import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CollapsibleSidebarItem from '../CollapsibleSidebarItem';
import TutorialModal from './TutorialModal';

import useSidebarData from './useSidebarData';

import main_edmonia_header from '../local_images/for_edmonia_header.png';
import alt_header_1 from '../local_images/logo_alt_1.png';
import alt_header_2 from '../local_images/logo_alt_2.png';
import alt_header_3 from '../local_images/logo_alt_3.png';
import alt_header_4 from '../local_images/logo_alt_4.png';


import '../index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinimize, faMaximize, faQuestion, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function DesktopSidebar() {
  const [openStateSignal, setOpenStateSignal] = useState(false);
  const [triggerSignal, setTriggerSignal] = useState(0);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);

  const headers = [
    main_edmonia_header,
    alt_header_1,
    alt_header_2,
    alt_header_3,
  ]

  const prevHeader = () => {
    setCurrentHeaderIndex((prevIndex) => (prevIndex === 0 ? headers.length - 1 : prevIndex - 1));
  };

  const nextHeader = () => {
    setCurrentHeaderIndex((prevIndex) => (prevIndex === headers.length - 1 ? 0 : prevIndex + 1));
  };


  const location = useLocation();
  const isCollagePage = location.pathname === '/collage';

  const openTutorial = () => {
    setIsTutorialOpen(true);
  };

  const closeTutorial = () => {
    setIsTutorialOpen(false);
  };


  const { categories, isLoading, error } = useSidebarData(process.env.REACT_APP_AIRTABLE_API_KEY);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const expandAll = () => {
    setOpenStateSignal(true);
    setTriggerSignal(prev => prev + 1);
  };

  const collapseAll = () => {
    setOpenStateSignal(false);
    setTriggerSignal(prev => prev + 1);
  };

  // const staticCategories = ["Offerings", "About", "Collage"];
  const staticCategories = ["Collage"];


  const getColorClass = (category) => {
    switch (category.toLowerCase()) {
      case 'art':
        return 'color-art';
      case 'about':
        return 'color-about';
      case 'offerings':
        return 'color-altar';
      case 'collage':
        return 'color-collage';
      case 'context':
        return 'color-context';
      case 'place':
        return 'color-place';
      case 'stewards':
        return 'color-stewards';
      default:
        return '';
    }
  };


  return (
    <div style={{
      display: 'flex',
      textAlign: "left",
      backgroundColor: "black",
      padding: "15px",
      height: "100vh",
      flexDirection: "column",
      overflowY: 'scroll'
    }}>
      {/* HEADER */}
      <div className='main_edmonia_header' style={{ width: "100%", position: "relative" }}>
        {/* <Link to='/' replace={isCollagePage} className="sidebar-link-header" style={{ display: 'block', width: '100%' }}>
          <img src={headers[currentHeaderIndex]} alt="For Edmonia, Together Sculpting" style={{
            maxWidth: currentHeaderIndex === 0 ? '100%' : '90%',
            height: 'auto',
            display: 'block'
          }} />
        </Link> */}
        <Link to='/' replace={isCollagePage} className="sidebar-link-header" style={{ display: 'block', width: '100%' }}>
          <img src={alt_header_3} alt="For Edmonia, Together Sculpting" style={{ maxWidth: '100%', height: 'auto', display: 'block' }} />
        </Link>
        {/* <div style={{ position: "absolute", bottom: "-20px", left: "50%", transform: "translateX(-50%)", display: "flex" }}>
          <button className="btn btn-link" onClick={prevHeader}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button className="btn btn-link" onClick={nextHeader}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div> */}
      </div>

      {/* SIDEBAR ELEMENTS AND EXPAND/COLLAPSE BUTTONS */}
      <div style={{ flex: 1 }}>
        <div className='sidebar_elements sidebar-font'>
          {/* dynamic categories WITH subcategories */}
          {Object.entries(categories).map(([category, subcategories]) => (
            <CollapsibleSidebarItem
              key={category}
              label={category}
              openStateSignal={openStateSignal}
              triggerSignal={triggerSignal}
              colorClass={getColorClass(category)}
            >
              {subcategories.map((subcategory, index) => (
                <div key={index} style={{ display: "flex" }}>
                  <span>&nbsp;&nbsp;&nbsp;-&nbsp;</span>
                  <Link
                    to={`/category/${category}/subcategory/${subcategory}`}
                    className={`sidebar-link ${getColorClass(category)}`}
                    style={{ textDecoration: 'none' }}>
                    {subcategory}
                  </Link>
                </div>
              ))}
            </CollapsibleSidebarItem>
          ))}

          {/* separation line */}
          <hr style={{ margin: "10px 0px" }}></hr>

          {/* Static categories without subcategories */}
          {staticCategories.map((category) => (
            <div key={category}>
              <Link
                to={`/${category.toLowerCase()}`}
                className={`sidebar-link ${getColorClass(category)}`}
                style={{ display: 'block', padding: '0px', textDecoration: 'none' }}>
                {category}
              </Link>
            </div>
          ))}
        </div>

        {/* BUTTONS */}

        {/* EXPAND AND COLLAPSE BUTTONS */}
        <div className='collapse_expand_buttons' style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <button className="btn btn-dark sidebar-button" style={{ marginBottom: '11px', width: '170px', fontSize: '13px' }} onClick={expandAll}>
            Expand Categories <FontAwesomeIcon icon={faMaximize} style={{ marginLeft: '4px' }} />
          </button>
          <button className="btn btn-dark sidebar-button" style={{ width: '170px', fontSize: '13px' }} onClick={collapseAll}>
            Collapse Categories <FontAwesomeIcon icon={faMinimize} style={{ marginLeft: '4px' }} />
          </button>
        </div>
      </div>

      {/* TUTORIAL BUTTON */}
      <div style={{ marginTop: '20px', marginBottom: '30px' }}>
        <button className="btn btn-outline-info" onClick={openTutorial}>
          Tutorial <FontAwesomeIcon icon={faQuestion} style={{ marginLeft: '4px' }} />
        </button>
      </div>


      {/* TUTORIAL MODAL */}
      {isTutorialOpen && (
        <TutorialModal isOpen={isTutorialOpen} onClose={closeTutorial} />
      )}

    </div >


  );
}

export default DesktopSidebar;