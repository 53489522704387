import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CollapsibleSidebarItem from '../CollapsibleSidebarItem';
import useSidebarData from './useSidebarData';

import main_edmonia_header from '../local_images/for_edmonia_header.png';
import alt_header_1 from '../local_images/logo_alt_1.png';
import alt_header_2 from '../local_images/logo_alt_2.png';
import alt_header_3 from '../local_images/logo_alt_3.png';
import alt_header_4 from '../local_images/logo_alt_4.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinimize, faMaximize, faQuestion, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import TutorialModal from './TutorialModal';

function MobileSidebar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [openStateSignal, setOpenStateSignal] = useState(false);
    const [triggerSignal, setTriggerSignal] = useState(0);
    const [isTutorialOpen, setIsTutorialOpen] = useState(false);
    const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);

    const location = useLocation();
    const isCollagePage = location.pathname === '/collage';

    const headers = [
        main_edmonia_header,
        alt_header_1,
        alt_header_2,
        alt_header_3,
    ]

    const prevHeader = () => {
        setCurrentHeaderIndex((prevIndex) => (prevIndex === 0 ? headers.length - 1 : prevIndex - 1));
    };

    const nextHeader = () => {
        setCurrentHeaderIndex((prevIndex) => (prevIndex === headers.length - 1 ? 0 : prevIndex + 1));
    };

    const openTutorial = () => {
        setIsTutorialOpen(true);
    };

    const closeTutorial = () => {
        console.log("Closing tutorial");
        setIsTutorialOpen(false);
    };

    const { categories, isLoading, error } = useSidebarData(process.env.REACT_APP_AIRTABLE_API_KEY);
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const expandAll = () => {
        setOpenStateSignal(true);
        setTriggerSignal(prev => prev + 1);
    };

    const collapseAll = () => {
        setOpenStateSignal(false);
        setTriggerSignal(prev => prev + 1);
    };

    const staticCategories = ["Offerings", "About", "Collage"];

    const getColorClass = (category) => {
        switch (category.toLowerCase()) {
            case 'art':
                return 'color-art';
            case 'about':
                return 'color-about';
            case 'offerings':
                return 'color-altar';
            case 'collage':
                return 'color-collage';
            case 'context':
                return 'color-context';
            case 'place':
                return 'color-place';
            case 'stewards':
                return 'color-stewards';
            default:
                return '';
        }
    };

    return (
        <div style={{ padding: "10px 20px 10px 20px" }}>
            {/* Header and Hamburger Menu Button */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Link to='/' replace={isCollagePage} className="sidebar-link-header" style={{ display: 'block', width: '100%' }}>
                        <img src={alt_header_4} alt="For Edmonia, Together Sculpting" style={{ maxWidth: '100%', height: 'auto', display: 'block' }} />
                    </Link>
                    {/* <div style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)', display: 'flex' }}>
                        <button className="btn btn-link" onClick={prevHeader}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button className="btn btn-link" onClick={nextHeader}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div> */}
                </div>
                <button className="btn btn-dark" onClick={toggleSidebar} style={{ marginLeft: "7px" }}>
                    ☰
                </button>
            </div>

            {/* Mobile Sidebar Content */}
            {isSidebarOpen && (
                <div style={{ textAlign: "left", marginBottom: "10px" }}>
                    <div className='sidebar_elements sidebar-font'>
                        {/* dynamic categories */}
                        {Object.entries(categories).map(([category, subcategories]) => (
                            <CollapsibleSidebarItem
                                key={category}
                                label={category}
                                openStateSignal={openStateSignal}
                                triggerSignal={triggerSignal}
                                colorClass={getColorClass(category)}
                            >
                                {subcategories.map((subcategory, index) => (
                                    <div key={index} style={{ display: "flex" }}>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <Link
                                            to={`/category/${category}/subcategory/${subcategory}`}
                                            className={`sidebar-link ${getColorClass(category)}`}
                                            style={{ textDecoration: 'none' }}>
                                            {subcategory}
                                        </Link>
                                    </div>
                                ))}
                            </CollapsibleSidebarItem>
                        ))}

                        {/* separation line */}
                        <hr style={{ margin: "10px 0px" }}></hr>

                        {/* Static categories without subcategories */}
                        {staticCategories.map((category) => (
                            <div key={category}>
                                <Link
                                    to={`/${category.toLowerCase()}`}
                                    className={`sidebar-link ${getColorClass(category)}`}
                                    style={{ display: 'block', padding: '0px', textDecoration: 'none' }}>
                                    {category}
                                </Link>
                            </div>
                        ))}
                    </div>

                    {/* BUTTONS */}
                    <div className='collapse_expand_buttons'
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'flex-start',
                            paddingTop: "20px"
                        }}>
                        <button className="btn btn-dark sidebar-button" style={{ marginBottom: "7px" }} onClick={expandAll}>
                            Expand Categories <FontAwesomeIcon icon={faMaximize} style={{ marginLeft: '4px' }} />
                        </button>
                        <button className="btn btn-dark sidebar-button" style={{ marginBottom: "28px" }} onClick={collapseAll}>
                            Collapse Categories <FontAwesomeIcon icon={faMinimize} style={{ marginLeft: '4px' }} />
                        </button>

                        <button className="btn btn-outline-info" onClick={openTutorial}>
                            Tutorial <FontAwesomeIcon icon={faQuestion} style={{ marginLeft: '4px' }} />
                        </button>
                    </div>
                </div>
            )}
            {/* TUTORIAL MODAL */}
            {isTutorialOpen && (
                <TutorialModal isOpen={isTutorialOpen} onClose={closeTutorial} />
            )}
        </div>
    );
}

export default MobileSidebar;


